<template>
	<div>
	<div class="register-container">
		<h2>Création Compte MiceCloud</h2>
		<form @submit.prevent="register">
			<v-text-field v-model="code" color="cyan darken" label="Votre code d'activation à 6 chiffres reçu par e-mail" placeholder="Commencez à taper..." loading class="token-input">
				<template v-slot:progress>
					<v-progress-linear :value="progress" :color="color" absolute height="10" ></v-progress-linear>
				</template>
			</v-text-field>
			<div style="display:flex; justify-content: space-between;">
				<div class="form-group">
					<label for="name">Nom :</label>
					<input type="text" id="name" v-model="nom" required>
				</div>
				<div class="form-group">
					<label for="firstName">Prénom :</label>
					<input type="text" id="firstName" v-model="prenom" required>
				</div>
			</div>
		
		<div class="form-group">
			<label for="business">Société :</label>
			<input type="text" id="business" v-model="societe" required>
		</div>
		<div class="form-group">
			<label for="username">Nom d'utilisateur :</label>
			<input type="text" id="username" v-model="username" required>
			<small class="hint">Le nom d'utilisateur doit contenir entre 8 et 20 caractères, comprenant au moins une lettre et un chiffre.</small>
		</div>
		<div class="form-group">
			<label for="email">Adresse e-mail :</label>
			<input type="email" id="email" v-model="email" required>
		</div>
		<div class="form-group">
			<label for="password">Mot de passe :</label>
			<input type="password" id="password" v-model="password" required>
			<small class="hint">Le mot de passe doit contenir entre 8 et 30 caractères, comprenant au moins une lettre majuscule, une lettre minuscule et un chiffre.</small>
		</div>
		<div class="form-group">
			<label for="confirmPassword">Confirmation du mot de passe :</label>
			<input type="password" id="confirmPassword" v-model="password2" required>
		</div>
		<div class="form-group row-between">
			<v-btn color="primary" dark v-bind="attrs" v-on="on" @click.prevent="TestData()" > Valider </v-btn>
			<router-link to="/login">Vers Login page</router-link>

		</div>
		<div class="liens">
			
				<v-dialog v-model="dialog" persistent max-width="600px" >
					<v-card>
						<v-card-title>
						<span class="text-h5">Il ne reste qu'une étape...</span>
						</v-card-title>
						<v-card-text>
							<span class="text-h7">Nous vous avons envoyé un code de vérification à : xxxxxxxxxxx@xxxxx  </span>
						<v-container>
							<v-row>
							<v-col cols="12" >
								<v-text-field label="Entrez le code de vérification reçu par e-mail" required v-model="code_input"></v-text-field>
							</v-col>
							</v-row>
						</v-container>
						</v-card-text>
						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="dialog = false, TestCode()" >Valider le code</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
		</div>
		</form>
  	</div>
	</div>
</template>
<script>
	import bcrypt from 'bcryptjs';
	export default {
		name: 'Login',
	
		data(){
			return {
				prenom: '',
				nom: '',
				societe: '',
				username: '',
				password: '',
				password2: '',
				email: '',
				code: '',
				dialog: false,
				code_input: '',
				errors: [],
				EmailExistenceLength:'',
				UsernameExistenceLength:'',
				CodeExistenceLength: '',
				CodeVerificationExistenceLength: '',
				maxOfUses: 0,
				numberOfUses: 0,
				date_code: '',
			}
		},

		computed: {
			progress () {
				return Math.min(100, this.code.length * 16.7)
			}, 
			color () {
				return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
			},
		},
		methods: {
			//1.1- Verifie que tous les champs sont remplis
			//1.2- Si oui, Verifie que les input sont au bon format
			//1.3- Verifie que le username, et l'email n'existent pas déjà dans la tables user_account
			//1.4- Verifie que le code de création existe et est valide
			//1.5- Si oui, envoie d'un code de vérification de l'email
			async TestData(){
				if(!this.username  || !this.password || !this.email || !this.password2 || !this.nom || !this.prenom || !this.societe || !this.code){
						this.errors.push(" please enter all fields")
					window.alert(this.errors)
					this.errors = []
					return
				}else{
					const existenceChecked = await Promise.all([this.CheckEmailExistence(), this.CheckUsernameExistence(), this.CheckCodeCreationExistence()])
					if(this.CodeExistenceLength > 0){
						if(this.numberOfUses < this.maxOfUses){
						}else{
							this.errors.push("Expired activation code")
						}
					}else{
						this.errors.push(" Verification code does not exist")
					}
					if(this.nom.length < 1){
						this.errors.push( " Name or first name invalid")
					}
					if(this.prenom.length < 1){
						this.errors.push( " Name or first name invalid")
					}
					if(this.username.length < 8){
						this.errors.push( " The username must be longer than 6 characters")
					}
					if(this.code.length !== 6){
						this.errors.push( " Incorrect verification code")
					}
					
					if(!(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/.test(this.username))){
						this.errors.push(" The username is not in the right format")
					}
					if(!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,30}$/.test(this.password)){
						this.errors.push( " Le mot de passe n'est pas au bon format")
					}
					if(this.password !== this.password2){
						this.errors.push( " Invalid passwords")
					}
					if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)){
						this.errors.push( " email pas au bon format")
					}
					if(this.UsernameExistenceLength > 0){
						this.errors.push(" The username already exists")
					}
					if(this.EmailExistenceLength > 0){
						this.errors.push(" Email is already in use")
					}
					if(this.errors.length > 0){
						window.alert(this.errors)	
						this.errors = []

					}else{
						this.dialog = true;
						this.SendEmailCodeVerification();
					}
				}
				
			},
			//2.1- Check de l'existence du code de vérification dans la table code_email_verification
			//2.2- Si oui, Check de la validité temporelle
			//2.3- Si oui, Insertion des informations rentrées dans la table user_account (nouvel utilisateur)
			//2.4- Incrémentation de 1 au nombre d'utilisation du code de création
			async TestCode(){
				const checkCodeExistence = await Promise.all([this.CheckCodeEmailVerificationExistence()]);
				if(checkCodeExistence){
					if(this.CodeVerificationExistenceLength > 0){
						let date_exp = this.date_code
						date_exp = parseInt(date_exp) + 900000;
						const date_actuelle = Date.now()
						if(date_actuelle <= date_exp){
							await this.postData();
							await this.AddOneToNumber();
							window.alert("Successful registration")
							return
						}else{
							window.alert('the code is outdated')
							return
						}
					}else{
						window.alert("No verification code")
						if(this.errors.length > 0){
							window.alert('Oops... There seems to be an error, please try again.')
						}
						return
					}
				}
			},
			//Requête de la 1.5 Envoie de l'email avec le code aléatoire généré + insertion du code dans la table code_email_verification
			async SendEmailCodeVerification(){
				const email = encodeURIComponent(this.email)
				const url= this.$api.getRESTApiUri() + `/email/code-verification/${email}`;
				
				return fetch(url)
				.then(res => res.text())
				.catch((error) => {
					console.log(error)
				});
			},
			//Requête de la 1.3
			async CheckEmailExistence(){
				const email = encodeURIComponent(this.email)
				const url = this.$api.getRESTApiUri() + `/check-email-existence/${email}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					this.EmailExistenceLength = data.length
				})
				.catch((error) => {
					console.log(error)
				});	
			},
			//Requête de la 1.3
			async CheckUsernameExistence(){
				const username = encodeURIComponent(this.username)
				const url = this.$api.getRESTApiUri() + `/check-username-existence/${username}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					
					this.UsernameExistenceLength = data.length
				})
				.catch((error) => {
					console.log(error)
				});	
			},
			//Requête de la 1.4
			async CheckCodeCreationExistence(){
				const code = encodeURIComponent(this.code)
				const url = this.$api.getRESTApiUri() + `/check-code-creation-existence/${code}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					
					this.CodeExistenceLength = data.length
					this.numberOfUses = data[0].number_of_uses;
					this.maxOfUses = data[0].max_of_uses;
				})
				.catch((error) => {
					console.log(error)
				});	
			},
			//Requête de la 2.1
			async CheckCodeEmailVerificationExistence(){
				const code = encodeURIComponent(this.code_input)
				const url = this.$api.getRESTApiUri() + `/check-code-verification-existence/${code}`
				
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					
					this.CodeVerificationExistenceLength = data.length
					this.date_code = data[0].date_now;
				})
				.catch((error) => {
					console.log(error)
				});	
				
			},
			//Requête de la 2.3
			async postData(){
				const url= this.$api.getRESTApiUri() + `/create-users`;
				const salt = bcrypt.genSaltSync(10);
				let PasswordHash = bcrypt.hashSync(this.password, salt);
				
				return fetch(url, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							username: this.username,
							password: this.password,
							email: this.email,
							nom: this.nom,
							prenom: this.prenom,
							societe: this.societe,
						}),
					})
				// Converting to JSON
				.then(response => response.json())
				// Displaying results to console
				.then(json => console.log(json))
			},
			//Requête de la 2.4
			async AddOneToNumber(){
				const url= this.$api.getRESTApiUri() + `/add-one`;
				return fetch(url, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							code: this.code
						}),
					})
				// Converting to JSON
				.then(response => response.json())
				// Displaying results to console
				.then(json => console.log(json));
			},
			async BackToLogin(){
				location.href = window.location.protocol + '/login'
			}
		}
	}
</script>
<style scoped>
.register-container {
	margin-top: 50px;
	margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  
}

.register-container form {
  width: 500px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.register-container h2 {
  font-family: Roboto, Light;
  font-size: 36px;
  font-weight: 300;
  color: var(--bleu);
  margin-bottom: 20px;
}
.token-input{
	
}
.form-group {
  margin-bottom: 20px;
}

.register-container label {
  display: block;
  color: var(--gris);
  margin-bottom: 5px;
}

.register-container input[type="text"],
.register-container input[type="email"],
.register-container input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--grisclair);
  border-radius: 5px;
}

.register-container button {
  background-color: var(--bleu);
  color: white !important;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-container button:hover {
  background-color: var(--turquoise);
}

.liens {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.liens a {
  color: var(--bleu);
  text-decoration: none;
}

.liens a:hover {
  font-weight: 500;
}

.hint {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    display: block;
}

</style>